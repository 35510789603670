.Header {
  margin: 0;
  padding: 2em 4em 0 4em;
  width: calc(100vw - 120px);
  background-color: white;
  display: flex;
  position: relative;
}

.Header .headings, .Header .headings * {
  display: block;
}

.Header .headings {
  width: 50%;
  margin: 0 auto;
  padding-top: 1em;
}

.Header * {
  display: inline-block;
  vertical-align: middle;
  align-items: center;
}

#brand-logo{
  height: 100%;
  width: 35px;
}

.Header a {
  height: 100%;
  position: absolute;
  left: 4em;
  top: 0;
}

.title {
  font-size: calc(0.5em + 1.5vw);
  line-height: 0.3;
  letter-spacing: 7.2px;
  color: #000000;
  background: linear-gradient(to top, #ffff7c 85%, transparent 50%);
  padding: 0 1em calc(0.5vw - 5px) 1em;
  margin: 0 auto;
  height: 10px;
}

.sub-title {
  font-size: calc(0.5em + 1vw);
  font-style: italic;
}

@media (max-width: 1652px) {
  .Header .headings {
    width: 60%;
  }
}

@media (max-width: 1200px) {
  .Header .headings {
    width: 70%;
  }
}

@media (max-width: 968px) {
  .Header .headings {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .Header {
    padding: 2em 0 0 0;
    width: calc(100%);
  }
  
  .Header .headings {
    width: 100%;
  }
  
  #brand-logo {
    display: none;
  }
  
  .title {
    letter-spacing: 3.6px;
  }
}

@media (max-width: 500px) {
  .title {
    padding: 0 2px calc(0.5vw - 5px) 2px;
  }
}