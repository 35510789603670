.Success {
  position: relative;
  top: 15vh;
  margin: 0 auto;
  padding: 0 10px;
}

.Success h1 {
  font-size: 6.25em;
}

.Success p {
  font-size: 1.2em;
  color: #1b2733;
}

@media (max-width: 425px) {
  .Success h1 {
    font-size: 3em;
  }
  
  .Success p {
    font-size: 1em;
  }
}