.Form {
  padding: 0 40px 80px 40px;
  position: relative;
  min-height: 75vh;
}

.Form * {
  box-sizing: border-box;
}

.Form main {
  margin: 0 20%;
}

.Form .row {
  margin: 0 auto;
  padding: 10px 0;
}

.Form label, #optional, #note {
  color: #6b6c6f;
  font-size: 0.8em;
  text-align: left;
}

.Form label {
  margin: 1.5em 1em 1.5em 5px;
}

.form-fields {
  justify-content: center;
  margin: 0 auto;
  position: relative;
}

.form-labels {
  justify-content: center;
  text-align: left;
}

.form-labels label {
  display: block;
  width: 176px;
  margin: 1.5em 0;
}

.form-fields label {
  display: block;
}

.Form input {
  padding: 10px;
  font-size: 1em;
  text-align: center;
  width: 11em;
}

#instructions {
  font-weight: normal;
  font-size: 1.5em;
  margin: 0;
  padding: 0.5em;
}

#instruction-details {
  margin: 0;
  font-size: 0.8em;
  color: #1b2733;
}

#netId {
  width: 60%;
  min-width: 35em;
  align-items: center;
  justify-content: space-between;
}

#netId * {
  margin: 0 10px;
}

#netId-btn {
  border-color: #e41e26;
  color: #e41e26;
  transition: background-color 0.5s ease;
}

#netId-btn:hover {
  background-color: #e41e26;
  color: white;
}

#queens-email, #course-search {
  width: 22em;
  max-width: 100%;
}

#search-bar-label, #email-label {
  width: 352px;
  max-width: 100%;
  margin: 1.5em auto;
}

#note {
  width: 352px;
  max-width: 100%;
  margin: 5px auto;
  transform: translateX(calc(0px - 1.4vw));
}

#submit-btn {
  background-color: #e41e26;
  color: white;
  transition: transform 0.5s ease;
}

#submit-btn:hover {
  transform: scale(1.05);
}

.Form .link {
  text-decoration: underline;
}

.Form .link:hover {
  cursor: pointer;
}

.Form #table-labels label {
  transform: translateX(calc(-12px - 1vw));
}

@media (max-width: 750px) {
  .Form {
    width: 90%;
    margin: 0 auto;
    padding: 0 0 80px 0;
  }
  
  .Form main {
    margin: 0;
  }
  
  .form-fields {
    
  }
}

@media (max-width: 500px) {
  #instructions {
    font-size: 0.75em;
  }
  
  #instruction-details {
    font-size: 0.5em;
  }
}

@media (max-width: 425px) {
  .Form .form-fields {
    width: 100%;
  }
  
  .Form .form-fields input {
    width: 50%;
  }
  
  .Form #table-labels label {
    transform: translateX(calc(-1px - 1vw));
  }
}