html, body {
  overflow-x: hidden;
  background-color: #fcfcfc;
  min-height: 100vh;
}

.App {
  text-align: center;
  position: relative;
  background-color: #fcfcfc;
  width: 100vw;
  box-sizing: border-box;
}

.toggler {
  text-align: left;
  position: fixed;
  bottom: 0;
  white-space: pre-wrap;
  padding-left: 2em;
  padding-bottom: 1em;
  width: 100vw;
  background-color: #fcfcfc;
}

.toggler a {
  color: inherit;
}

.bold-italic {
  font-style: italic;
  font-weight: bold;
}

.flexbox {
  display: flex;
}

.btn {
  display: inline-block;
  border-radius: 50px;
  padding: 0.5em 3em;
  height: 40px;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 0.9em;
}

.btn:hover {
  cursor: pointer;
}

.vert-aligner {
  height: 100%;
}

.delete-icon {
  margin: 8px 1vw 0 1vw;
  color: lightgrey;
}

.delete-icon:hover {
  cursor: pointer;
  color: #4a4a4a;
}

/* NORMALIZE CSS */
[type="text"], [type="email"], [type="password"], textarea, button {
  border-style: none;
  outline: none;
  border: 1px solid #dfe2e6;
}

button:focus {
  outline: none;
}

@media (max-width: 425px) {
  footer p {
    font-size: 0.8em;
  }
}